var FilterSeminaries = function (category) {//, maxItemsPerPage) {
    var self = this;
    
    //static
    self.category = category;
   
    self.GetArticles = function (data) {
        console.log("hej");
            $.ajax({
                url: "/umbraco/api/Ajax/FilterSeminaries/",
                type: "GET",
                data: { contentId: self.category },
                dataType: "json",
                success: function (returnData) {
                    if (returnData.Data.NewsList) {
                      //var currentNewsList = ko.mapping.fromJSON(returnData.Data.NewsList);
                      //self.startNewsList(self.startNewsList().concat(currentNewsList()));                       
                    }
                    if (returnData.Data.End === true) {
                        self.disabled(true);
                    } else {
                        self.disabled(false);
                    }
                   

                },
                error: function (e) {
                    console.log(e.statusText + ": " + e.responseText); //todo: better error handling
                },
                complete: function () {
                 
                }
            });

    }

    self.GetArticles("");
    return self;
};