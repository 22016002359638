var NewsListViewModel = function (pageId, cultureCode) {//, maxItemsPerPage) {
    var self = this;
    
    //static
    self.pageId = pageId;
    self.cultureCode = cultureCode;
    self.maxItemsPerPage = 10;

    //observables
    self.disabled = ko.observable(false);
    self.startNewsList = ko.observableArray([]);
   self.pageNr = ko.observable(1);
   // self.paginationVisible = ko.observable(false);
    //self.PaginationButtonClick = function (number, btn) {
    //    $(".pagination-btns").parent().removeClass("active");
    //    btn.parent().addClass("active");
    //    self.pageNr(number);
    //    //self.GetNews("", 6);
    //    var category = self.selectedCategory();
    //    self.GetFilteredNews("", category, 6);
    //};
    self.NextButtonClick = function () {
        if (self.disabled() === false) {
            
            self.pageNr(parseInt(self.pageNr()) + 1);            
            self.GetNews("");
        }
        
    };
   
    self.GetNews = function (data) {
       
            $.ajax({
                url: "/umbraco/api/Ajax/GetNews/",
                type: "GET",
                data: { contentId: self.pageId, cultureCode: self.cultureCode, pageNr: self.pageNr(), maxItemsPerPage: self.maxItemsPerPage }, //self.maxItemsPerPage },
                dataType: "json",
                success: function (returnData) {
                    if (returnData.Data.NewsList) {
                        var currentNewsList = ko.mapping.fromJSON(returnData.Data.NewsList);
                        self.startNewsList(self.startNewsList().concat(currentNewsList()));
                                             
                    }
                    if (returnData.Data.End === true) {
                        self.disabled(true);
                    } else {
                        self.disabled(false);
                    }
                   

                },
                error: function (e) {
                    console.log(e.statusText + ": " + e.responseText); //todo: better error handling
                },
                complete: function () {
                 
                }
            });

    }

   self.GetNews("");
    return self;
};